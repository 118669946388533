// Here you can add other styles

#root {
  font-family: "Roboto";
  height: 100%;
}

@font-face {
  src:url(../Themes/Fonts/Roboto/Roboto-Regular.ttf);
  font-family: Roboto;
}

@media print {
  html, body {
    width: 214px;
    padding: 12px;
  }
}

.ant-btn-primary {
  background-color: $primaryColor;
  color: $whiteColor;
  font-weight: 600;
  font-size: 14px;
  border-radius: 4px;
  border-color: $whiteColor;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-btn-primary:focus {
  @extend .ant-btn-primary;
}

.ant-btn-primary:active {
  @extend .ant-btn-primary;
}

.ant-btn-primary:hover {
  @extend .ant-btn-primary;
}

.ant-btn {
  @extend .ant-btn-primary;
}

.ant-btn:focus {
  @extend .ant-btn-primary;
}

.ant-btn:active {
  @extend .ant-btn-primary;
}

.ant-btn:hover{
  @extend .ant-btn-primary;
}

.ant-input {
  border-radius: 4px;
  font-size: 16px;
  background-color: $whiteColor !important;
}

.ant-modal-content {
  border-radius: 20px;
}