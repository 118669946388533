// Variable overrides

/* Mixin */
/* Set các giá trị với màn hình tương ứng */
$mobile-width: 480px;
$tablet-width: 768px;
$desktop-width: 1024px;
$mac-desktop-width: 1440px;
$full-desktop-width: 1920px;
$full-desktop-height: 950px;

@mixin mobileScreen {
  @media (max-width: #{$mobile-width - 1px}) {
    @content;
  }
}

@mixin tabletScreen {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktopScreen {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin macScreen {
  @media (min-width: #{$desktop-width}) and (max-width: #{$mac-desktop-width}) {
    @content;
  }
}

@mixin notFullHdScreen {
  @media (max-width: #{$full-desktop-width - 1} or (max-height :#{$full-desktop-height - 1})) {
    @content;
  }
}

$primaryColor: #720d5d;
$whiteColor: #ffffff;
$primaryTextColor: #474747;
$gray: #979797;
$borderFormInput: #eaeaf4;
$blueText: #1074ff;
$orangeText: #f78f00;
$legendInput : #af9fac;
$grayText: #919EAB;
$redText: #F53838;
$backgroundColor: #F9FAFB;
$blackText: #212B36;
$greenTex: #27AE60;
$printText: #3C4B64;
:export {
  primaryColor: $primaryColor;
}