.margin-50 {
  margin-top: 50px;
}

.content-prompt{
  text-align: center;
  font-size: 16px;
  font-family: Roboto, serif;
  color: rgba(0, 0, 0, 0.47);
}
.button-install{
  display: table;
  background-color: #720D5D;
  border-radius: 20px;
  color: white;
  font-family: Roboto, serif;
  font-weight: bold;
  padding:12px 60px;
  margin: 20px auto;
}

.instruction {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-chevron {
  color: rgba(0, 0, 0, 0.47);
}
